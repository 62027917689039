import styled from "styled-components";

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;

  .tooltip-text {
    filter: opacity(0);
    background-color: black;
    color: #ffffff;
    text-align: center;
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 13px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    transition: filter 0.15s ease-in;
  }

  &:hover .tooltip-text {
    filter: opacity(1);
  }
`;

const Tooltip = ({ children, text }) => {
  return (
    <TooltipWrapper>
      {children}
      <span className="tooltip-text">{text}</span>
    </TooltipWrapper>
  );
};

export default Tooltip;
