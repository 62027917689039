import axios from "axios";
import { API_URL } from "../api";

export const bAddEvent = async (eventData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/analytics/`, eventData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
