import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initAmplitude } from "./lib/amplitude";

const instance = createInstance({
  urlBase: "https://internal.presenceof.me/analytics,",
  siteId: 1,
  trackerUrl: "https://internal.presenceof.me/analytics/matomo.php", // optional, default value: `${urlBase}matomo.php`
  srcUrl: "https://internal.presenceof.me/analytics/matomo.js", // optional, default value: `${urlBase}matomo.js`
});

initAmplitude();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MatomoProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
