import axios from "axios";
import { API_URL } from "../api";

export const bGetProfileByUrl = async (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/user/profile/${url}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
