import React from "react";
import styled from "styled-components";
import { colors } from "./constants";

const StyledButton = styled.button`
  background-color: ${({ outline }) =>
    outline ? "#00000000" : colors.primary};
  border: solid 2px
    ${({ noBorder }) => (noBorder ? "#00000000 !important" : colors.primary)};

  ${({ block }) => (block ? "width: 100%" : "")};

  color: ${({ outline }) => (outline ? colors.primary : "#ffffff")};
  font-size: ${({ small }) => (small ? "14px" : "16px")};
  padding: ${({ small }) => (small ? "4px 8px" : "8px 16px")};
  border-radius: ${({ small }) => (small ? "16px" : "32px")};
  cursor: pointer;
  transition: all 0.15s ease-in;
  font-weight: bold;

  :hover {
    background-color: ${colors.primaryHover};
    border-color: ${colors.primaryHover};
    color: #ffffff;
  }

  :active {
    filter: brightness(1.1);
  }

  :disabled {
    cursor: not-allowed;
    filter: grayscale(1);
  }
`;

const DangerButton = styled(StyledButton)`
  background-color: ${({ outline }) => (outline ? "#00000000" : colors.danger)};
  border: solid 2px ${colors.danger};

  color: ${({ outline }) => (outline ? colors.danger : "#ffffff")};
  :hover {
    background-color: ${colors.dangerHover};
    border-color: ${colors.dangerHover};
    color: #000000;
  }
`;

const InfoButton = styled(StyledButton)`
  background-color: ${({ outline }) => (outline ? "#00000000" : colors.info)};
  border: solid ${colors.info};

  color: ${({ outline }) => (outline ? colors.info : "#000000")};
  :hover {
    background-color: ${colors.infoHover};
    border-color: ${colors.infoHover};
    color: #000000;
  }
`;

const TextButton = styled(StyledButton)`
  background-color: #00000000;
  border: solid #00000000;

  color: ${colors.primary};
  :hover {
    background-color: ${colors.infoHover};
    border-color: ${colors.infoHover};
    color: ${colors.primary};
  }
`;

const WhiteTextButton = styled(StyledButton)`
  background-color: #00000000;
  border: solid #00000000;

  color: ${colors.info};
  :hover {
    background-color: ${colors.infoHover};
    border-color: ${colors.infoHover};
    color: ${colors.primary};
  }
`;

const Button = ({ children, status, ...props }) => {
  switch (status) {
    case "info":
      return <InfoButton {...props}>{children}</InfoButton>;
    case "danger":
      return <DangerButton {...props}>{children}</DangerButton>;
    case "text":
      return <TextButton {...props}>{children}</TextButton>;
    case "text-light":
      return <WhiteTextButton {...props}>{children}</WhiteTextButton>;
    default:
      return <StyledButton {...props}>{children}</StyledButton>;
  }
};

export default Button;
