import styled from "styled-components";
import Tooltip from "../components/Tooltip";
import { linkList } from "../lib/linkList";

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 16px;
  justify-content: center;
`;

const Link = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: solid 1px #bdc3c777;
  background-color: #ecf0f177;
  color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.15s ease-in;

  &:hover {
    background-color: #bdc3c777;
  }
`;

const SocialLinks = ({ links = [], color, onLinkClick = () => {} }) => {
  const onClick = (l) => {
    onLinkClick(l.id);
  };

  return (
    <LinksWrapper>
      {links.map((l) => (
        <Tooltip text={l.type} key={`social-link-${l.url}`}>
          <a
            href={`https://${l.url}`}
            target="_blank"
            onClick={() => onClick(l)}
          >
            <Link color={color}>{linkList[l.type]?.icon}</Link>
          </a>
        </Tooltip>
      ))}
    </LinksWrapper>
  );
};

export default SocialLinks;
