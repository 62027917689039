import React from "react";
import styled from "styled-components";

const StyledFooter = styled.div`
  display: flex;
  
  background-color: #a5b1c277;
  padding: 32px 16px;
  height: 50px;
  align-items: center;

  button {
    white-space: nowrap;
  }
`;

const Footer = ({ children, ...props }) => {
  return <StyledFooter {...props}>{children}</StyledFooter>;
};

export default Footer;
