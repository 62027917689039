import amplitude from "amplitude-js";
import { useEffect, useRef } from "react";

export const initAmplitude = () => {
  amplitude.getInstance().init("2f9c41fc802314bbf29fbc1483302fc6");
};

export const logEvent = (eventName, eventProps = {}, userId = null) => {
  if (process.env.NODE_ENV !== "production") {
    console.log("---------- Amplitude tracking ----------");
    console.log("Event name: " + eventName);
    console.log(eventProps);
    console.log(userId);
    console.log("----------------------------------------");
    return;
  }

  amplitude.getInstance().logEvent(eventName, {
    user_id: userId,
    event_properties: { environment: process.env.NODE_ENV, ...eventProps },
  });
};

export const useLogEvent = (eventName, eventProps = {}, userId = null) => {
  const triggered = useRef(false);
  useEffect(() => {
    if (triggered.current) {
      return;
    }
    logEvent(eventName, eventProps, userId);
    triggered.current = true;
  }, []);
};

export const EVENT = {
  PAGE_VIEW_HOMEPAGE: "PAGE_VIEW_HOMEPAGE",
  PAGE_VIEW_SIGNUP: "PAGE_VIEW_SIGNUP",
  CLICK_SIGNUP: "CLICK_SIGNUP",
};
