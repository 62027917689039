import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import styled, { createGlobalStyle } from "styled-components";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Avatar from "../../components/Avatar";
import ProfileHeader from "../../components/ProfileHeader";
import Footer from "../../components/Footer";
import Links from "../../components/Links";
import SocialLinks from "../../components/SocialLinks";
import { bGetProfileByUrl } from "../../backend/profile/url";
import {
  AppContainer,
  Centered,
  Separator,
} from "../../components/layout/Other";
import { colors } from "../../components/layout/constants";
import CookieConsent from "react-cookie-consent";
import Col from "../../components/layout/Col";
import Row from "../../components/layout/Row";
import Button from "../../components/layout/Button";
import { bAddEvent } from "../../backend/event/event";
import { backgrounds, backgroundUrl } from "../../lib/backgrounds";
import { convertDataToHtml } from "../../lib/editorjs-parser";

const GlobalStyles = createGlobalStyle`
  body {
    background-color: #f5f6fa;
    ${({ bgColor }) => {
      if (bgColor.substr(0, 1) === "#") {
        return `background-color: ${bgColor}`;
      }

      if (bgColor.substr(0, 6) === "image:") {
        const bgId = parseInt(bgColor.substr(6), 10);
        return `background-image: url("${backgroundUrl}/${backgrounds[bgId].urlLarge}");
      background-size: cover;
      background-position: center;`;
      }

      return bgColor;
    }};

    transition: background 0.2s linear, background-image 0.2s linear, background-color 0.2s linear;
  }

  ${({ font }) =>
    font
      ? `* {
      font-family: ${font} !important;
    }`
      : ""}

`;

const FooterRow = styled(Row)`
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.primary}f0;
  filter: opacity(${({ opacity }) => opacity});
  transition: filter 0.3s ease-in;
`;

const BigWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  margin-top: 80px;
  border-radius: 8px;
  padding: 16px;
  width: 90vw;
  max-width: 1000px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  position: relative;
  min-height: 100px;
  transition: all 0.15s ease-in;
  z-index: 2;
  background-size: cover;

  ${({ isEmbed }) =>
    isEmbed
      ? `margin-top: 0 !important; width: 100% !important; border-radius: 0;`
      : ""};

  ${({ bgColor }) => {
    if (bgColor.substr(0, 1) === "#") {
      return `background-color: ${bgColor}`;
    }

    if (bgColor.substr(0, 6) === "image:") {
      const bgId = parseInt(bgColor.substr(6), 10);
      return `background-image: url("${backgroundUrl}/${backgrounds[bgId].urlLarge}");
      background-size: cover;
      background-position: center;`;
    }

    return bgColor;
  }};

  @media screen and (max-width: 992px) {
    width: 96vw;
    margin-top: 40px;
    ${({ isEmbed }) =>
      isEmbed ? `margin-top: 0 !important; width: 100% !important;` : ""};
  }
`;

const BasicWrapper = styled.div`
  margin-top: 80px;
  border-radius: 8px;
  padding: 16px;
  width: 90vw;
  max-width: 1000px;
  position: relative;
  min-height: 100px;
  transition: all 0.15s ease-in;
  z-index: 2;
  background-size: cover;
  background-color: ${colors.primary};
  ${({ isEmbed }) =>
    isEmbed ? `margin-top: 0 !important; width: 100% !important;` : ""};

  @media screen and (max-width: 992px) {
    width: 96vw;
    margin-top: 40px;
    ${({ isEmbed }) =>
      isEmbed ? `margin-top: 0 !important; width: 100% !important;` : ""};
  }
`;

const BackButton = styled.button`
  padding: 8px 4px;
  border-radius: 2px;
  border: solid 1px #bdc3c777;
  background-color: #ecf0f177;
  color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.15s ease-in;
  width: 100%;
  margin-bottom: 24px;

  &:hover {
    background-color: #bdc3c777;
  }
`;

const Preview = () => {
  const { url } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const doNotTrack = !!urlParams.get("no-track");
  const isEmbed = !!urlParams.get("embed");

  const [profile, setProfile] = useState({
    settings: {},
    info: {},
    links: [],
    headlineLinks: [],
    pages: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [profileFetched, setProfileFetched] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);

  const sendViewEvent = (id) => {
    if (doNotTrack) {
      return;
    }
    bAddEvent({ profileId: id, type: "PROFILE_VIEW", data: {} }).then(() => {});
  };

  const sendLinkEvent = (id, linkId) => {
    if (doNotTrack) {
      return;
    }
    bAddEvent({ profileId: id, type: "LINK_CLICK", data: { linkId } }).then(
      () => {}
    );
  };

  useEffect(() => {
    setIsLoading(true);
    bGetProfileByUrl(url)
      .then((p) => {
        sendViewEvent(p.info.id);
        setProfile(p);
        setProfileFetched(true);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setError(
          "Could not fetch the profile. Please check your URL and try again."
        );
      });
  }, [url]);

  return (
    <>
      {(isLoading || error) && (
        <BigWrapper>
          <BasicWrapper>
            {isLoading && (
              <>
                <Skeleton
                  height={32}
                  baseColor={colors.primary}
                  highlightColor={colors.primaryHover}
                />
                <Separator size={32} />
                <Skeleton
                  height={240}
                  baseColor={colors.primary}
                  highlightColor={colors.primaryHover}
                />
              </>
            )}
            {error && (
              <Centered>
                <Separator size={32} />
                <h4 style={{ color: "#ffffff" }}>{error}</h4>
                <Separator size={32} />
              </Centered>
            )}
          </BasicWrapper>
        </BigWrapper>
      )}
      {error && <span>{error}</span>}
      <Overlay opacity={profileFetched ? 0 : 1} />
      {profileFetched && (
        <>
          <GlobalStyles
            bgColor={profile.settings.mainBgColor}
            font={profile.settings.font}
          />
          <BigWrapper isEmbed={isEmbed}>
            <Wrapper bgColor={profile.settings.bgColor} isEmbed={isEmbed}>
              <ProfileHeader
                info={profile.info}
                headline={profile.headline}
                settings={profile.settings}
                bio={profile.bio}
              />
              {profile.headlineLinks.length > 0 && (
                <SocialLinks
                  links={profile.headlineLinks}
                  color={profile.settings.buttonTextColor}
                  onLinkClick={(linkId) =>
                    sendLinkEvent(profile.info.id, linkId)
                  }
                />
              )}

              {selectedPage && (
                <>
                  <Separator size={16} />
                  <BackButton
                    onClick={() => setSelectedPage(null)}
                    color={profile.settings.buttonTextColor}
                  >
                    <FaArrowLeft />
                  </BackButton>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: convertDataToHtml(selectedPage?.content),
                    }}
                  />
                </>
              )}
              {!selectedPage && (
                <Links
                  links={profile.links}
                  color={profile.settings.buttonTextColor}
                  size={profile.settings.buttonSize}
                  radius={profile.settings.buttonRadius}
                  onLinkClick={(linkId) =>
                    sendLinkEvent(profile.info.id, linkId)
                  }
                  onSelectPage={(pId) => {
                    setSelectedPage(profile.pages?.find((p) => p.id === pId));
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                />
              )}
            </Wrapper>
          </BigWrapper>

          <Separator size={32} />

          <Footer>
            <AppContainer>
              <FooterRow>
                <Col flex={8}>
                  <Row gap={16}>
                    <a href="/privacy-policy" target="_blank">
                      <Button status="info" noBorder outline small>
                        privacy policy
                      </Button>
                    </a>
                  </Row>
                </Col>
                <Col flex={2}>
                  <a href="/">
                    <Button status="info" noBorder outline small>
                      get your own page
                    </Button>
                  </a>
                </Col>
              </FooterRow>
            </AppContainer>
          </Footer>
        </>
      )}

      {!isEmbed && (
        <CookieConsent
          location="bottom"
          buttonText="works for me"
          cookieName="presence_of_me_preview_cookie_consent"
          style={{ background: "blueviolet" }}
          buttonStyle={{
            backgroundColor: "#f0932b",
            border: "solid 2px #f0932b",
            color: "#ffffff",
            fontSize: "16px",
            padding: "8px 16px",
            borderRadius: "32px",
          }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      )}
    </>
  );
};

export default Preview;
