import { useEffect } from "react";
import {
  AppContainer,
  RoundContainer,
  Separator,
} from "../../components/layout/Other";
import { Link } from "react-router-dom";
import styled from "styled-components";

const BigLogo = styled.div`
  color: #ffffff;
  font-size: 48px;
  font-weight: bold;
  padding: 8px 24px;

  transition: font-size 0.3s ease-in;
  background-color: blueviolet;
  display: inline-block;

  @media only screen and (max-width: 900px) {
    font-size: 24px;
    margin-left: 16px;
  }
`;
const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AppContainer>
      <Separator size={32} />
      <Link to="/">
        <BigLogo>presenceof.me</BigLogo>
      </Link>
      <Separator size={32} />
      <RoundContainer>
        <h1>Refund Policy</h1>
        <p>Updated at 2022-07-13</p>
        <p>presenceof.me</p> <h1>Definitions and key terms</h1>{" "}
        <p>
          <span>
            To help explain things as clearly as possible in this Return &amp;
            Refund Policy, every time any of these terms are referenced, are
            strictly defined as:
          </span>
        </p>{" "}
        <ul>
          <li>
            <span>
              <strong>Company:</strong> when this policy mentions “Company,”
              “we,” “us,” or “our,” it refers to <a>Colorcode SRL</a>,{" "}
              <a>8A Domnisori street, bl. 8D, ap. 10, Ploiesti, Romania</a> that
              is responsible for your information under this Return &amp; Refund
              Policy.presenceof.me
            </span>
          </li>
          <li>
            <span>
              <strong>Customer:</strong> refers to the company, organization or
              person that signs up to use the Service to manage the
              relationships with your consumers or service users.presenceof.me
            </span>
          </li>
          <li>
            <span>
              <strong>Device:</strong> any internet connected device such as a
              phone, tablet, computer or any other device that can be used to
              visit and use the services.presenceof.me
            </span>
          </li>
          <li>
            <span>
              <strong>Service:</strong> refers to the service provided by{" "}
              <a>presenceof.me</a> as described in the relative terms (if
              available) and on this platform.presenceof.me
            </span>
          </li>
          <li>
            <span>
              <strong>Website:</strong> <a>presenceof.me</a>’s site, which can
              be accessed via this URL: <a>.</a>presenceof.me
            </span>
          </li>
          <li>
            <span>
              <strong>You:</strong> a person or entity that is registered with{" "}
              <a>presenceof.me</a> to use the Services.presenceof.me
            </span>
          </li>
        </ul>
        <p>presenceof.me</p>
        <h1>Shipping</h1>{" "}
        <p>
          <span>
            <a>presenceof.me</a> is responsible for return shipping costs. Every
            returning shipping is paid by <a>presenceof.me</a>, even if the item
            didn’t have free shipping in the first place.
          </span>
        </p>{" "}
        <p>presenceof.me</p> <h1>Return &amp; Refund Policy</h1>{" "}
        <p>
          <span>
            Thanks for shopping at <a>presenceof.me</a>. We appreciate the fact
            that you like to buy the stuff we build. We also want to make sure
            you have a rewarding experience while you’re exploring, evaluating,
            and purchasing our products.
          </span>
        </p>{" "}
        <p>
          <span>
            As with any shopping experience, there are terms and conditions that
            apply to transactions at <a>presenceof.me</a>. We’ll be as brief as
            our attorneys will allow. The main thing to remember is that by
            placing an order or making a purchase at <a>presenceof.me</a>, you
            agree to the terms set forth below along with <a>presenceof.me</a>
            ’s&nbsp;Privacy Policy.
          </span>
        </p>
        <p>
          <span>
            If there’s something wrong with the product you bought, or if you
            are not happy with it, you will not be able to issue a refund for
            your item.
          </span>
        </p>
        <p>presenceof.me</p> <h1>Refunds</h1>{" "}
        <p>
          <span>
            We at&nbsp;
            <a>presenceof.me</a>&nbsp;commit ourselves to serving our customers
            with the best products. Every single product that you choose is
            thoroughly inspected, checked for defects and packaged with utmost
            care. We do this to ensure that you fall in love with our products.
            presenceof.mepresenceof.me Sadly, there are times when we may not
            have the product(s) that you choose in stock, or may face some
            issues with our inventory and quality check. In such cases, we may
            have to cancel your order. You will be intimated about it in advance
            so that you don't have to worry unnecessarily about your order. If
            you have purchased via Online payment (not Cash on Delivery), then
            you will be refunded once our team confirms your request.
            presenceof.mepresenceof.me We carry out thorough quality check
            before processing the ordered item. We take utmost care while
            packing the product. At the same time we ensure that the packing is
            good such that the items won’t get damaged during transit. Please
            note that <a>presenceof.me</a> is not liable for damages that are
            caused to the items during transit or transportation.
            presenceof.mepresenceof.me We follow certain policies to ensure
            transparency, efficiency and quality customer care:
          </span>
        </p>
        <li>
          <span>
            We DO NOT allow returns on sold products - online or in retail
            outlets.
          </span>
        </li>
        <li>
          <span>
            We DO NOT accept returned goods, as we believe that customers should
            get the best quality products.
          </span>
        </li>
        <li>
          <span>
            Refunds are NOT given for any purchases made - be they online or in
            retail store.
          </span>
        </li>
        <li>
          <span>We DO NOT encourage exchanges of our products.</span>
        </li>
        <li>
          <span>
            We DO NOT engage in reselling used products and discourage the same,
            because we cannot ensure the best quality products for our
            customers.
          </span>
        </li>
        <p>
          <span>For International Orders:</span>
        </p>
        <li>
          <span>We DO NOT support Exchanges or Returns.</span>
        </li>
        <li>
          <span>
            If you cancel the order before we process it and dispatch for
            shipping, a refund can be processed. Orders generally take 1-2 days
            to process before dispatch.
          </span>
        </li>
        <li>
          <span>
            Orders already in shipping cannot be returned, canceled or refunded.
          </span>
        </li>
        <li>
          <span>
            If you face any issues, please contact our Support Team immediately.
          </span>
        </li>
        <p>presenceof.me</p> <h1>Your Consent</h1>{" "}
        <p>
          <span>
            By using our platform, registering an account, or making a purchase,
            you hereby consent to our Return &amp; Refund Policy and agree to
            its terms.
          </span>
        </p>{" "}
        <p>presenceof.me</p> <h1>Changes To Our Return &amp; Refund Policy</h1>{" "}
        <p>
          <span>
            Should we update, amend or make any changes to this document so that
            they accurately reflect our Service and policies. Unless otherwise
            required by law, those changes will be prominently posted here.
            Then, if you continue to use the Service, you will be bound by the
            updated Return &amp; Refund Policy. If you do not want to agree to
            this or any updated Return &amp; Refund Policy, you can delete your
            account.
          </span>
        </p>{" "}
        <p>presenceof.me</p> <h1>Contact Us</h1>{" "}
        <p>
          <span>
            If, for any reason, You are not completely satisfied with any good
            or service that we provide, don't hesitate to contact us and we will
            discuss any of the issues you are going through with our product.
          </span>
        </p>
        <ul>
          <li>
            <span>
              Via this Link:{" "}
              <a href="https://form.typeform.com/to/CtHXgBZV?typeform-source=presenceof.me">
                https://form.typeform.com/to/CtHXgBZV?typeform-source=presenceof.me
              </a>
            </span>
          </li>
        </ul>
      </RoundContainer>
      <Separator size={32} />
    </AppContainer>
  );
};

export default RefundPolicy;
