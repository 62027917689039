import { useEffect, useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import CookieConsent from "react-cookie-consent";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import "./App.scss";
import { colors } from "./components/layout/constants";
import { AppContainer, Centered, Separator } from "./components/layout/Other";
import Col from "./components/layout/Col";
import Row from "./components/layout/Row";
import Button from "./components/layout/Button";
import Footer from "./components/layout/Footer";
import { Link, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./screens/other/PrivacyPolicy";
import TermsOfUse from "./screens/other/TermsOfUse";
import Preview from "./screens/preview/Preview";
import RefundPolicy from "./screens/other/RefundPolicy";
import { EVENT, useLogEvent } from "./lib/amplitude";
import bgSignUp from "./assets/bg2.svg";
import bgColorful from "./assets/bg6.svg";

const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1112;
  background-color: ${colors.primary};
  padding: 16px;
  left: 0;
  right: 0;
`;

const JumboTitle = styled.div`
  text-align: center;
  font-size: 8vh;
  color: ${colors.primaryHover};
  font-weight: bold;

  @media only screen and (max-width: 900px) {
    font-size: 6vh;
  }
`;

const Screen = styled.div`
  height: 80vh;
  background-color: ${colors.primary};
  padding: 40px;
  //background-image: linear-gradient(${colors.primary} 0%, ${colors.primary} 70%, ${colors.primaryHover} 100%);
  background-size: cover;

  @media only screen and (max-width: 900px) {
    padding: 40px 10px;
  }
`;

const Screen2 = styled.div`
  min-height: 80vh;
  background-color: #ffffff;
  padding: 40px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    padding: 40px 10px;
    height: unset;
  }
`;

const ScreenWithBg = styled(Screen2)`
  background-size: cover;

  ${JumboTitle} {
    color: rgb(183, 203, 43);
    color: rgb(255, 255, 255);
    mix-blend-mode: difference;
  }
`;

const Screen3 = styled.div`
  min-height: 80vh;
  background-color: #ff7f50;
  padding: 40px;
  display: flex;
  align-items: center;
  ${JumboTitle} {
    color: #ffffff;
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    padding: 40px 10px;
    height: unset;
    justify-content: center;
  }
`;

const BigText = styled.div`
  position: absolute;
  color: #ffffff11;
  font-size: 14.8vw;
  font-weight: bold;
  left: 0;
  top: calc(80vh - 17vw);
`;

const BigLogo = styled.div`
  color: #ffffff;
  font-size: ${({ small }) => (small ? "24px" : "48px")};
  font-weight: bold;
  padding: 8px 24px;

  transition: font-size 0.3s ease-in;
  /*
  background-image: linear-gradient(
    90deg,
    #ffffff00 0%,
    blueviolet 10%,
    blueviolet 50%,
    blueviolet 90%,
    #ffffff00 100%
  );
  */
  display: inline-block;

  @media only screen and (max-width: 900px) {
    font-size: 24px;
    padding-left: 0;
  }
`;

const JumboText = styled.div`
  text-align: center;
  font-size: 3.5vw;
  color: #ffffff;
  font-weight: bold;
  margin-top: 20vh;

  @media only screen and (max-width: 900px) {
    font-size: 32px;
  }
`;

const Underlined = styled.span`
  background-color: blueviolet;
  color: #cccccc;
  padding: 0 8px;
  font-weight: bold;

  text-shadow: -0.0625rem -0.0625rem 0 #6bffff, 0.0625rem 0.0625rem 0 #ff819e;
`;

const Screenshot = styled.img`
  max-height: 60vh;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: blue 0px 0px 0px 2px inset, rgb(31, 193, 27) 10px 10px,
    rgb(255, 217, 19) 20px 20px, rgb(255, 156, 85) 30px 30px,
    rgb(255, 85, 85) 40px 40px;
  height: fit-content;
`;

const Screenshot2 = styled.img`
  max-height: 60vh;
  border-radius: 24px;
  overflow: hidden;
  //box-shadow: blueviolet 0px 0px 0px 8px;
  width: 100%;
  height: fit-content;
`;

const VideoshotContainer = styled.div`
  border-radius: 24px;
  overflow: hidden;
`;

const Videoshot = styled.video`
  max-height: 60vh;
  overflow: hidden;
  //box-shadow: blueviolet 0px 0px 0px 8px;
  width: 100%;
  height: fit-content;
`;

const ScreenshotCol = styled(Col)`
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 900px) {
    justify-content: center;
  }
`;

const DescriptionCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Description = styled.div`
  text-align: center;
  margin: 20px;
  font-size: 24px;
  font-weight: 200;
`;

const Super = styled.span`
  font-weight: bold;
  text-shadow: -4px 4px #ef3550, -8px 8px #f48fb1, -12px 12px #7e57c2,
    -16px 16px #2196f3, -20px 20px #26c6da, -24px 24px #43a047,
    -28px 28px #eeff41, -32px 32px #f9a825, -36px 36px #ff5722;
`;

const Super2 = styled.span`
  text-decoration: underline;
  text-decoration-color: #f9a825;
`;

const JumboButton = styled(Button)`
  font-size: 2vw;
  color: #ffffff;
  border-radius: 3vw;
  padding: 1vw 2vw;

  @media only screen and (max-width: 900px) {
    font-size: 24px;
    border-radius: 24px;
    padding: 8px 16px;
  }
`;

const Price = styled.div`
  //background-image: linear-gradient(
  //  90deg,
  //  #ffffff00 0%,
  //  blueviolet 10%,
  //  blueviolet 50%,
  //  blueviolet 90%,
  //  #ffffff00 100%
  //);

  background-color: blueviolet;
  border-radius: 48px;
  color: #ffffff;
  padding: 16px 32px;
  font-size: 4vw;
  margin: 16px;
`;

const PriceExplanation = styled.div`
  padding: 16px 32px;
  font-size: 24px;
  color: #ffffff;
  font-weight: bold;

  @media only screen and (max-width: 900px) {
    font-size: 20px;
  }
`;

const InfoRow = styled(Row)`
  @media only screen and (max-width: 900px) {
    flex-direction: column !important;
  }
`;

const BigMenuWrapper = styled.div`
  display: block;

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

const SmallMenuWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 900px) {
    display: block;
  }
`;

const FloatingMenu = styled.div`
  position: fixed;
  left: 0;
  top: ${({ show }) => (show ? 0 : "-100vh")};
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: ${colors.primary}dd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  padding: 32px;
  z-index: 1111;

  transition: top 0.3s ease-in-out;
`;

const FloatingMenuButton = styled.div`
  position: absolute;
  right: 16px;
  top: 20px;
`;

const FooterRow = styled(Row)`
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterLinksRow = styled(Row)`
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
`;

const GlobalStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const aboutyou = "about you";
const catchphrases = [
  <>
    beautiful <Underlined>{aboutyou}</Underlined> links, as simple as they get.
  </>,
  <>
    simple <Underlined>{aboutyou}</Underlined> links, as beautiful as you.
  </>,
  <>
    you'll never want another <Underlined>{aboutyou}</Underlined> link again.
  </>,
];

const c = catchphrases[Math.floor(Math.random() * catchphrases.length)];

function App() {
  const [scrolled, setScrolled] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);

  const featuresRef = useRef(null);
  const pricingRef = useRef(null);

  const { trackPageView, trackEvent } = useMatomo();

  const scrollTo = (ref) => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    trackPageView();
  }, []);

  useLogEvent(EVENT.PAGE_VIEW_HOMEPAGE);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 40) {
        setScrolled(true);
      }
      if (window.scrollY === 0) {
        setScrolled(false);
      }
    });
  }, [setScrolled]);

  return (
    <div>
      <Header>
        <Row>
          <Col flex={1}>
            <BigLogo small={scrolled}>presenceof.me</BigLogo>
          </Col>
          <Col style={{ margin: "auto 0" }}>
            <BigMenuWrapper>
              <Row gap={16}>
                <Button
                  status="text-light"
                  outline
                  onClick={() => scrollTo(featuresRef)}
                >
                  features
                </Button>
                <Button
                  status="text-light"
                  outline
                  onClick={() => scrollTo(pricingRef)}
                >
                  pricing
                </Button>
                <a
                  href="https://app.presenceof.me"
                  onClick={() =>
                    trackEvent({
                      category: "landing-page",
                      action: "click-get-started-event",
                    })
                  }
                >
                  <Button status="danger">get started for free</Button>
                </a>
              </Row>
            </BigMenuWrapper>
            <SmallMenuWrapper>
              <Button
                status="info"
                outline
                onClick={() => setShowFloatingMenu(!showFloatingMenu)}
              >
                menu
              </Button>
            </SmallMenuWrapper>
            <FloatingMenu show={showFloatingMenu}>
              <FloatingMenuButton>
                <Button
                  status="info"
                  outline
                  onClick={() => setShowFloatingMenu(!showFloatingMenu)}
                >
                  close
                </Button>
              </FloatingMenuButton>
              <Button
                status="info"
                onClick={() => {
                  scrollTo(featuresRef);
                  setShowFloatingMenu(false);
                }}
              >
                features
              </Button>
              <Button
                status="info"
                onClick={() => {
                  scrollTo(pricingRef);
                  setShowFloatingMenu(false);
                }}
              >
                pricing
              </Button>
              <a
                href="https://app.presenceof.me"
                onClick={() =>
                  trackEvent({
                    category: "landing-page",
                    action: "click-get-started-event",
                  })
                }
                style={{ width: "100%" }}
              >
                <Button status="danger" block>
                  get started for free
                </Button>
              </a>
            </FloatingMenu>
            {showFloatingMenu && <GlobalStyles />}
          </Col>
        </Row>
      </Header>

      <Screen>
        <BigText>presenceof.me</BigText>
        <AppContainer>
          <Centered>
            <JumboText>{c}</JumboText>
            <Separator size={24} />
            <a href="/john.doe" style={{ zIndex: 1 }}>
              <JumboButton status="danger" style={{ zIndex: 1 }}>
                see how it looks
              </JumboButton>
            </a>
          </Centered>
        </AppContainer>
      </Screen>
      <Screen2 ref={featuresRef}>
        <AppContainer>
          <InfoRow>
            <DescriptionCol flex={4}>
              <JumboTitle>create your own page</JumboTitle>
              <Description>
                easily shareable with others, make your presenceof.me page your
                single source of truth.
                <Separator />
                <a href="/john.doe">
                  <Button outline>see an example here</Button>
                </a>
              </Description>
            </DescriptionCol>
            <ScreenshotCol flex={4}>
              <Screenshot src={require("./assets/screenshots/2.png")} />
            </ScreenshotCol>
          </InfoRow>
        </AppContainer>
      </Screen2>
      {/*#7bed9f*/}
      <Screen2 style={{ backgroundColor: "#eeeeee" }}>
        <AppContainer>
          <InfoRow gap={64} style={{ flexDirection: "row-reverse" }}>
            <DescriptionCol flex={6}>
              <JumboTitle>
                <Super>super</Super> customizable
              </JumboTitle>
              <Description>
                do whatever you want and need with your presenceof.me page
              </Description>
            </DescriptionCol>
            <ScreenshotCol flex={4}>
              <Screenshot2 src={require("./assets/screenshots/3.png")} />
            </ScreenshotCol>
          </InfoRow>
        </AppContainer>
      </Screen2>

      <Screen2>
        <AppContainer>
          <InfoRow gap={64}>
            <DescriptionCol flex={6}>
              <JumboTitle>
                add <Super2>as many links</Super2> as you want
              </JumboTitle>
              <Description>easily add, update and reorder them</Description>
            </DescriptionCol>
            <ScreenshotCol flex={4}>
              <Screenshot2 src={require("./assets/screenshots/4.png")} />
            </ScreenshotCol>
          </InfoRow>
        </AppContainer>
      </Screen2>

      {/*
      <ScreenWithBg style={{ backgroundImage: `url(${bgColorful})` }}>
        <AppContainer>
          <InfoRow gap={64}>
            <DescriptionCol flex={6}>
              <JumboTitle>
                you can go wild with colors and enthusiasm...
              </JumboTitle>
            </DescriptionCol>
            <ScreenshotCol flex={4}>
              <Screenshot2 src={require("./assets/screenshots/6.png")} />
            </ScreenshotCol>
          </InfoRow>
        </AppContainer>
      </ScreenWithBg>

      <ScreenWithBg>
        <AppContainer>
          <InfoRow gap={64}>
            <ScreenshotCol flex={4}>
              <Screenshot2 src={require("./assets/screenshots/5.png")} />
            </ScreenshotCol>
            <DescriptionCol flex={6}>
              <JumboTitle>
                or you can take the simple and neat approach!
              </JumboTitle>
            </DescriptionCol>
          </InfoRow>
        </AppContainer>
      </ScreenWithBg>
*/}

      <ScreenWithBg style={{ backgroundImage: `url(${bgColorful})` }}>
        <AppContainer>
          <InfoRow gap={64}>
            <DescriptionCol flex={5}>
              <JumboTitle>
                add custom pages using the included editor
              </JumboTitle>
            </DescriptionCol>
            <ScreenshotCol flex={5}>
              <Screenshot2 src={require("./assets/screenshots/7.png")} />
              {/*
              <VideoshotContainer>
                <Videoshot controls={false} loop={true} autoPlay={true}>
                  <source
                    src={require("./assets/screenshots/pages-vid.mp4")}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </Videoshot>
              </VideoshotContainer>
*/}
            </ScreenshotCol>
          </InfoRow>
        </AppContainer>
      </ScreenWithBg>

      <ScreenWithBg style={{ backgroundColor: "#dfe6e9" }}>
        <AppContainer>
          <InfoRow gap={64}>
            <ScreenshotCol flex={5}>
              <Screenshot2 src={require("./assets/screenshots/8.png")} />
              {/*
              <VideoshotContainer>
                <Videoshot controls={false} loop={true} autoPlay={true}>
                  <source
                    src={require("./assets/screenshots/pages-vid.mp4")}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </Videoshot>
              </VideoshotContainer>
*/}
            </ScreenshotCol>
            <DescriptionCol flex={5}>
              <JumboTitle>
                analyse your viewers with the analytics page
              </JumboTitle>
            </DescriptionCol>
          </InfoRow>
        </AppContainer>
      </ScreenWithBg>

      <Screen3 ref={pricingRef} style={{backgroundColor: '#a29bfe'}}>
        <AppContainer>
          <Centered>
            <JumboTitle>pricing</JumboTitle>
            <Row style={{ width: "100% " }}>
              <Col flex={1}>
                <Centered>
                  <Price>free</Price>
                  <PriceExplanation>maximum 20 links</PriceExplanation>
                  <PriceExplanation>analytics</PriceExplanation>
                  <PriceExplanation>highly customisable</PriceExplanation>
                  <PriceExplanation>custom name</PriceExplanation>
                  <PriceExplanation>support for media embed</PriceExplanation>
                  <PriceExplanation>additional WYSIWYG pages</PriceExplanation>
                </Centered>
              </Col>
              <Col flex={1}>
                <Centered>
                  <Price>9.90E / month</Price>
                  <PriceExplanation>all features in free tier</PriceExplanation>
                  <PriceExplanation>unlimited links</PriceExplanation>
                  <PriceExplanation>
                    remove presenceof.me branding
                  </PriceExplanation>
                  <PriceExplanation>priority support</PriceExplanation>
                  <PriceExplanation>
                    priority for suggested features
                  </PriceExplanation>
                </Centered>
              </Col>
            </Row>
          </Centered>
        </AppContainer>
      </Screen3>

      <Screen style={{ backgroundImage: `url(${bgSignUp})` }}>
        <AppContainer>
          <Centered>
            <JumboText>
              get started for free, no credit card required.
            </JumboText>
            <Separator size={20} />
            <a
              href="https://app.presenceof.me"
              onClick={() =>
                trackEvent({
                  category: "landing-page",
                  action: "click-get-started-event",
                })
              }
            >
              <JumboButton status="danger">get started for free</JumboButton>
            </a>
          </Centered>
        </AppContainer>
      </Screen>

      <Footer>
        <AppContainer>
          <FooterRow>
            <Col flex={8}>
              <FooterLinksRow gap={16}>
                <Link to={"/privacy-policy"}>
                  <Button status="info" noBorder outline small>
                    privacy policy
                  </Button>
                </Link>
                <Link to={"/terms-of-use"}>
                  <Button status="info" noBorder outline small>
                    terms of use
                  </Button>
                </Link>
                <Link to={"/refund-policy"}>
                  <Button status="info" noBorder outline small>
                    refund policy
                  </Button>
                </Link>
                <a href="https://form.typeform.com/to/CtHXgBZV" target="_blank">
                  <Button status="info" noBorder outline small>
                    contact us
                  </Button>
                </a>
              </FooterLinksRow>
            </Col>
            <Col flex={2}>
              <a
                href="https://www.linkedin.com/company/colorcode-romania"
                target="_blank"
              >
                <Button status="info" noBorder outline small>
                  Copyright &copy; Colorcode 2022.
                </Button>
              </a>
            </Col>
          </FooterRow>
        </AppContainer>
      </Footer>

      <CookieConsent
        location="bottom"
        buttonText="works for me"
        cookieName="presence_of_me_cookie_consent"
        style={{ background: "blueviolet" }}
        buttonStyle={{
          backgroundColor: "#f0932b",
          border: "solid 2px #f0932b",
          color: "#ffffff",
          fontSize: "16px",
          padding: "8px 16px",
          borderRadius: "32px",
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
}

const RoutedApp = () => {
  return (
    <Routes>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/support" element={<RefundPolicy />} />
      <Route path="/:url" element={<Preview />} />
      <Route path="/" element={<App />} />
    </Routes>
  );
};

export default RoutedApp;
