import styled from "styled-components";
import { useEffect, useState } from "react";
import Row from "./layout/Row";
import Col from "./layout/Col";
import { Centered } from "./layout/Other";
import Avatar from "./Avatar";
import { backgrounds, backgroundUrl } from "../lib/backgrounds";

const S3_BUCKET_AVATARS_URL =
  "https://presenceofme-space.fra1.digitaloceanspaces.com/avatars/";

const FloatingAvatar = styled.div`
  position: absolute;
  top: -36px;
  ${({ avatarPosition }) =>
    avatarPosition === "left" ? "left: 20px;" : "left: calc(100% - 90px);"};

  ${({ isEmbed }) => (isEmbed ? `top: 10px !important;` : "")};

  border-radius: 50%;
  //box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
  //  rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
  //  rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

const FloatingAvatarSmall = styled.div`
  display: none;

  border-radius: 50%;
  //box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
  //  rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
  //  rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  ${({ isEmbed }) => (isEmbed ? `top: 10px !important;` : "")};

  @media screen and (max-width: 992px) {
    display: flex;
    position: absolute;
    top: -18px;
    left: 10px;
  }
`;

const Name = styled.div`
  text-align: center;
  font-size: ${({ small }) => (small ? "24px" : "36px")};
  font-weight: bold;
  color: ${({ color }) => color};
`;

const Headline = styled.div`
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  color: ${({ color }) => color};
`;

const Location = styled.div`
  text-align: center;
  font-size: 13px;
  color: ${({ color }) => color};
`;

const Bio = styled.div`
  text-align: center;
  font-size: 13px;
  color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

const BigWrapper = styled.div`
  //filter: opacity(${({ visible }) => (visible ? 1 : 0)});
`;

const MiniWrapper = styled.div`
  position: fixed;

  ${({ bgColor }) => {
    if (bgColor.substr(0, 1) === "#") {
      return `background-color: ${bgColor}`;
    }

    if (bgColor.substr(0, 6) === "image:") {
      const bgId = parseInt(bgColor.substr(6), 10);
      return `background-image: url("${backgroundUrl}/${backgrounds[bgId].urlLarge}");
      background-size: cover;
      background-position: center;`;
    }

    return `${bgColor}; background-size: 100% 200px;`;
  }};

  width: 100%;
  left: 0;
  z-index: 11;
  top: ${({ visible }) => (visible ? "0" : "-100px")};
  padding: 16px;

  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  transition: top 0.3s ease-in;
`;

const MiniAvatarSmall = styled.div`
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  ${({ isEmbed }) => (isEmbed ? `top: 10px !important;` : "")};
  display: flex;
`;

const ProfileHeader = ({
  info: { name, headline, location, bio },
  settings = {},
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const isEmbed = !!urlParams.get("embed");
  const [minimized, setMinimized] = useState(false);

  useEffect(() => {
    if (isEmbed) {
      return;
    }
    window.addEventListener(
      "scroll",
      () => {
        setMinimized(window.pageYOffset > 80);
      },
      { passive: true }
    );
  }, []);

  return (
    <>
      <FloatingAvatar
        avatarPosition={settings.avatarPosition}
        isEmbed={isEmbed}
      >
        <Avatar
          name={name}
          size={72}
          background={settings.mainBgColor}
          photo={
            settings.avatarUrl
              ? S3_BUCKET_AVATARS_URL + settings.avatarUrl
              : null
          }
        />
      </FloatingAvatar>
      <FloatingAvatarSmall isEmbed={isEmbed}>
        <Avatar
          name={name}
          size={48}
          background={settings.mainBgColor}
          photo={
            settings.avatarUrl
              ? S3_BUCKET_AVATARS_URL + settings.avatarUrl
              : null
          }
        />
      </FloatingAvatarSmall>

      <BigWrapper visible={!minimized}>
        <Name color={settings.mainColor}>{name}</Name>
        {headline && <Headline color={settings.textColor}>{headline}</Headline>}
        {location && <Location color={settings.textColor}>{location}</Location>}
        {bio && (
          <Bio greyed={!bio} color={settings.textColor}>
            {bio}
          </Bio>
        )}
      </BigWrapper>
      <MiniWrapper bgColor={settings.bgColor} visible={minimized}>
        <Row gap={16} style={{ justifyContent: "center" }}>
          <Col>
            <Avatar
              name={name}
              size={32}
              background={settings.mainBgColor}
              photo={
                settings.avatarUrl
                  ? S3_BUCKET_AVATARS_URL + settings.avatarUrl
                  : null
              }
              noBorder
            />
          </Col>
          <Col>
            <Centered>
              <Name color={settings.mainColor} small>
                {name}
              </Name>
            </Centered>
          </Col>
        </Row>
      </MiniWrapper>
    </>
  );
};

export default ProfileHeader;
