import styled from "styled-components";
import {
  getColorFromNameHash,
  getContrastColor,
  getInitials,
} from "../lib/utils";

//color: ${({ name }) => getContrastColor(getColorFromNameHash(name))};
//background-color: ${({ name }) => getColorFromNameHash(name)};
const AvatarWrapper = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => Math.ceil(size / 2) + 2}px;
  font-size: ${({ size }) => (size * 2) / 5}px;
  text-align: center;
  background-color: ${({ name, bgColor }) =>
    bgColor || getColorFromNameHash(name)};
  ${({ background }) =>
    background && background.substr(0, 1) === "#"
      ? `background-color: ${background}`
      : background};
  text-shadow: 0 0 5px #000000;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ photo }) =>
    photo
      ? `
  background-image: url(${photo});
  background-size: cover;
  background-blend-mode: unset;
  background-position: center;
  `
      : ""};

  ${({ noBorder }) => (noBorder ? "" : "border: solid 4px #ffffff;")}
`;

const Avatar = ({ name, photo, background, noBorder, size = 48 }) => {
  return (
    <>
      <AvatarWrapper
        size={size}
        name={name}
        background={background}
        photo={photo}
        noBorder={noBorder}
      >
        {!photo && getInitials(name)}
      </AvatarWrapper>
    </>
  );
};

export default Avatar;
